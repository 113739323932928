.template-front-page {
  .top-content {
    @apply text-white bg-zz-orange;

    .inner {
      @apply flex justify-between flex-col lg:flex-row gap-4;

      .the_content {
        max-width: 950px;
      }
    }

    .button {
      @apply flex items-end lg:justify-end;
    }
  }

  .thumbnail-container {
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
    }
  }
}