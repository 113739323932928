
.form-container {

  input:not([type="submit"]):not([type="file"]):not([type="checkbox"]), select, textarea {
    background: none !important;
    outline: none !important;
    border-radius: 46px;
    border: 2px solid;

    font-size: 24px;
    line-height: 28px;

  }

  textarea {
    border-radius: 20px;
  }

  .gfield_label {
    font-size: 24px;
    line-height: 28px;

    font-weight: normal !important;

    @apply text-zz-green;
    .gfield_required {
      @apply text-zz-green;
    }
  }

  .gfield-has-floating-label {
    position: relative;

    label {
      margin: 0;
      position: absolute;
      top: 10px;
      left: 10px;
      @apply transition-all;
    }

    &.gfield--active {
      label {
        top: 0;
        transform: scale(0.75);
        opacity: .7;
        transform-origin: top left;
      }
    }
  }

  .gfield_label_before_complex {
    display: none !important;
  }

  .ginput_container_consent {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      width: 35px;
      height: 35px;
      visibility: hidden;
      position: relative;

      &:before {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid;
        border-radius: 5px;
        font-size: 18px;
        line-height: 18px;
        @apply border-zz-green text-zz-green;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        content: '';
      }

      &:checked {
        &:before {
          content: '\f00c';
        }
      }
    }

    .gfield_consent_label {
      font-size: 16px;
      margin-left: 15px;
      line-height: 1.2;
    }
  }

  .gfield_consent_description {
    border: none !important;
    max-width: 511px;
    font-size: 14px !important;
    line-height: 1.2 !important;
  }

  .gform_heading {
    display: none;
  }
}