.recensies-slider {
  position: relative;

  .splide__slide {
    .inner {
      .the_content {
        max-width: 1000px;
        text-align: center;
        margin: auto;

        //font-size: clamp(25px, 34px, 3vw);
        line-height: 1.2;
      }
    }

    .author {
      display: block;
      text-align: center;
      font-size: clamp(18px, 22px, 3vw);
      line-height: 1.6;
      margin: 30px auto 0 auto;
    }
  }

  .splide__arrows {
    @apply relative md:absolute;

    @apply md:-translate-y-1/2;

    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;

    pointer-events: none;

    display: flex;
    justify-content: space-between;

    .splide__arrow {
      pointer-events: auto;

      font-size: 16px;

      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      border: 1px solid;
      @apply text-zz-green border-zz-green;

      &:hover {
        @apply bg-zz-green text-zz-white;
      }

    }
  }
}