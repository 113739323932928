.voor_wie {
  @apply bg-zz-blue-light;

  .the_content {
    max-width: 1000px;
  }

  .image-container {
    width: 100%;
    overflow: hidden;

    .img {
      width: 105%;
      margin-left: -2.5%;
    }
  }
 }