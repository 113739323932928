.usps {
  .inner {
    @apply grid sm:grid-cols-2 lg:grid-cols-4 gap-12;

    .usps-item {
      .usps-item-icon {
        max-width: 215px;
        max-height: 215px;
        width: 100%;
        aspect-ratio: 1/1;
        margin: 0 auto 30px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @apply bg-zz-orange;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 30px;
        }
      }

      .usps-item-text {
        text-align: center;

        p {
          $bg: rgba(#ffc0ca, .8);

          padding:5px 0; /* top and bottom padding */
          @apply text-zz-green font-semibold;

          font-size: 18px;
          line-height: 1.2; /* use this to create horizontal space between lines */
          //display: inline;
          //background-color:$bg;
          //box-shadow: 10px 0 0 0 $bg, -10px 0 0 0 $bg; /* left/right padding  (IE8 needs fallback) */
          //left:10px; /* match px of box-shadow above to align text */
          //position: relative;
        }
      }
    }
  }
}