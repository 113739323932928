.programma {
  @apply bg-zz-blue-light;

  .title {

  }

  .programma-items {
    @apply grid grid-cols-1 lg:grid-cols-4;

    .programma-item {
      padding: 0 10px 50px 10px;

      .programma-item-title {
        @apply lg:min-h-[290px];
        h3 {
          font-size: 34px;
          line-height: 46px;
        }
      }

      border-left: 2px solid;
      @apply border-zz-orange;

      &:first-of-type {
        @apply lg:border-none;
      }
    }
  }
 }