@mixin spacing($spacing: 100px) {
  $onSmall: $spacing * 0.5;

  @apply pt-[#{$onSmall}] lg:pt-[#{$spacing}];
  @apply pb-[#{$onSmall}] lg:pb-[#{$spacing}];

  &-top {
    @apply pt-[#{$onSmall}] lg:pt-[#{$spacing}];
  }

  &-bottom {
    @apply pb-[#{$onSmall}] lg:pb-[#{$spacing}];
  }
}

.spacing {
  @include spacing(100px);

  &-small {
    @include spacing(50px);
  }
}