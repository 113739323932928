.mobile_menu {
  @apply bg-zz-orange text-zz-white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: scroll;
  display: none;

  &.menu-open {
    display: block;
  }

  .content {
    padding-top: 130px;

    nav {
      .menu-item-object-wpml_ls_menu_item {
        display: none;
      }

      li {
        a {
          font-weight: 400;
          font-size: 28px;
          line-height: 1.2;
        }
      }
    }
  }
}

// prevent scroll on mobile menu
body.menu-open {
  height: 100vh;
  overflow-y: hidden;
}