.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 111111;
  height: 100vh;
  background: rgba(black, .5);

  &.close {
    display: none;
  }

  &.open {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-column {
    display: flex;
    flex-direction: row;

    .modal-column-outside {
      opacity: 0;
      width: 20vw;
      height: 100vh;
    }

    .modal-row {
      display: flex;
      flex-direction: column;

      .modal-row-outside {
        opacity: 0;
        width: 100%;
        height: 10vh
      }

      .close-button {
        font-size: 150%;
        padding: 0;
        margin-left: auto;
        transform: translate(-2vw, 35px);
      }

      .inner-row {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 60vw;
        min-height: 10vh;
        background: white;
        padding: 3vw 5vw;
        max-height: 80vh;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 15px;

        .inner-tekst {
          margin-bottom: 5vh;
        }

        .inner {

          .gform_body {
            margin-top: 3vh;
            input, textarea {
              border-radius: 10px;
              border: #d7d7d7 1px solid;
            }

            .name_first, .name_last {
              display: flex;
              flex-direction: column-reverse;
            }
          }
        }
      }

    }
  }
}