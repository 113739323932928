$gap: 30px;

.faq {
  .faq-items {
    @apply col-count-1 md:col-count-2;
    //column-count: 2;
    column-gap: $gap;

    .faq-item {
      grid-row: 2;
      grid-column: 1;
      justify-self: start;
      break-inside: avoid-column;

      .question {
        @apply bg-zz-orange text-zz-green;
        padding: 45px;
        margin-bottom: $gap;
        border-radius: 50px;
      }
    }
  }
}