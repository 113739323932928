header {
  transition: all 0.3s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 11111;

  @apply bg-zz-orange;

  .content {
    transition: all 0.3s ease-in-out;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 308px;
      max-height: 45px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }

    .menu-section {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        @apply text-zz-white;
        @apply gap-8;

        li {
          a {
            font-size: 18px;
            line-height: 1.2;
            @apply text-zz-white font-bold;
          }

          &.menu-item-has-children {
            position: relative;

            > .sub-menu {
              padding: 10px;
              position: absolute;
              top: 100%;
              right: 0;
              width: max-content;
              display: none;
              @apply bg-zz-white shadow-xl;

              a {
                @apply text-zz-orange;
              }
            }

            &:hover {
              > .sub-menu {
                display: block;
              }
            }
          }
        }
      }
    }

    .mobile-menu-section {
      [data-menu-toggle] {
        i {
          font-size: 34px;
          line-height: 1;
        }

        cursor: pointer;
        .show-on-open {
          display: none;
        }

        &.menu-open {
          .hide-on-open {
            display: none;
          }
          .show-on-open {
            display: block;
          }
        }
      }
    }
  }

  .menu > .wpml-ls-item {
    &:before {
       // font awesome icon: f0ac
        content: "\f0ac";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        margin-right: 5px;
        font-size: 18px;
    }
  }

  .wpml-ls-item {
    a {
      font-weight: normal !important;
    }
  }

  &[data-has-scrolled="true"] {
    @apply shadow-2xl;

    .content {
      padding: 20px 0;
    }
  }
}