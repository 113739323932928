.call_to_action {
  @apply bg-zz-orange;

  .content {
    .the_content {
      text-align: center;
      max-width: 1000px;
      margin: auto;
    }

    .links {
      margin-top: 50px;
      @apply flex justify-center flex-col md:flex-row gap-[10px];
    }
  }
}