.btn {
  display: inline-block;
  border-radius: 100px;
  transition: all .2s ease;

  padding: 25px 35px;

  font-size: 20px;
  line-height: 24px;
  @apply font-medium;

  &-green {
    @apply bg-zz-green text-zz-yellow;

    &:hover {
      @apply bg-zz-yellow text-zz-green;
    }
  }

  &-orange {
    border: 1px solid;
    @apply border-zz-green text-zz-green bg-zz-orange;

    &:hover {
      @apply bg-zz-green text-zz-orange;
    }
  }
}

.gform_button {
  display: inline-block;
  border-radius: 100px;
  transition: all .2s ease;

  padding: 25px 35px;

  font-size: 20px;
  line-height: 24px;
  @apply font-medium;
  @apply text-zz-green bg-zz-orange;

  &:hover {
    @apply bg-zz-green text-zz-orange;
  }
}