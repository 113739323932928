.wie_gingen_je_voor {
  .top-image {
    img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }
  }

  .images {
    @apply grid grid-cols-2 lg:grid-cols-4 gap-12;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 220px;
      }
    }
  }
}