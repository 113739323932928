.footer-img {
  width: 100%;
  height: 480px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

footer {
  @apply bg-zz-green text-zz-blue;

  .footer-inner {
    font-size: 20px;
    line-height: 1.2;

    a.orange-link {
      @apply text-zz-orange underline hover:no-underline;
    }

    .logo-section {
      img.logo {
        width: 100%;
        max-width: 375px;
      }
    }

    .footer-row {
      @apply grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-24;
    }

    .social-media-icons {
      a {
        font-size: 25px;
        line-height: 25px;
        @apply text-zz-blue no-underline hover:text-zz-orange transition-all;
      }
    }

    .logo-in-text {
      @apply flex flex-col md:flex-row gap-[10px] md:items-end;

      img {
        max-height: 30px;
      }
    }
  }
}