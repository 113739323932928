.training {
  .training-image {
    width: 100%;
    height: 100%;
    max-height: 475px;
    object-fit: cover;
  }

  .text-content {
    .image-item {
      margin-bottom: 50px;

      img {
        max-width: 970px;
        margin: auto;
      }
    }

    .text-item {
      max-width: 1077px;

      ul {
        li {
          margin-left: 3vw;
        }
      }
    }
  }
}
