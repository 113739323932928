@import 'variables';

@import 'components';

html {
  scroll-behavior: smooth;
}

body {
  font-family: silka, sans-serif;
  font-size: clamp(14px, 24px, 2vw);
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  @apply text-zz-green;

  display: flex;
  flex-direction: column;

  main {
    @apply flex-1;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-size: clamp(25px, 46px, 3vw);
  line-height: 1.3;
}

h2 {
  font-size: clamp(25px, 48px, 3vw);
  line-height: 1.2;
  @apply font-bold;
  margin-bottom: 20px;
}

h3,h4,h5 {
   font-size: clamp(20px, 34px, 3vw);
   line-height: 1.2;
   margin-bottom: 20px;
}



.the_content {
  &.titles-small {
    h1,h2,h3,h4,h5,h6 {
      font-size: clamp(25px, 38px, 3vw);
      line-height: 1.5;
      margin-bottom: 20px;
      @apply font-normal;
    }
  }
}

.max-width-content {
  max-width: 950px;
}
