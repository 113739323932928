.expeditieleiders {
  .items {
    .item {
      padding: 50px 0;
      @apply grid grid-cols-1 lg:grid-cols-2 gap-12;

      .left {
        @apply grid md:grid-cols-2 gap-8;

        img {
          width: 100%;
          max-width: 339px;
          aspect-ratio: 1/1;
          object-fit: cover;
          object-position: top;
        }

        .content {
          .name, .function {
            font-size: 30px;
            line-height: 40px;
            margin: 0;
          }

          .function {
            @apply italic;
          }
        }
      }

      .right {
        .the_content {
          font-size: clamp(18px, 24px, 3vw);
          line-height: 1.5;
        }
      }

      &:not(:last-of-type) {
        border-bottom: 2px solid;
        @apply border-zz-orange;
      }

      &:last-of-type {
        padding-bottom: 0 !important;
      }
    }
  }

  .leaders {
    @apply gap-8;
    display: flex;
    flex-direction: column;
    align-items: center;

    picture {
      width: 50%;

      img {
        width: 100%;
        height: 75%;
        object-fit: cover;
        object-position: center;
      }
    }

    p{
      width: 50%;
      display: flex;
      align-content: center;
    }
  }
}